<template>
  <card>
    <div slot="header">
      <h4 class="card-title">Transaction History</h4>
    </div>
    <div class="row">
      <div class="col-sm-2" v-if="false">
        <el-select
          v-model="defaultData"
          class="select-info"
          placeholder="Type"
          size="large"
        >
          <el-option
            v-for="i in 10"
            :key="i"
            :label="i"
            :value="i"
            class="select-info"
          ></el-option>
        </el-select>
      </div>
      <div class="col-sm-2">
        <div style="padding-top: 11px; padding-bottom: 11px; display: flex">
          <div style="line-height: 2.5"><span>From</span></div>
          <fg-input class="ml-3">
            <el-date-picker
              v-model="transactionFilter.period_start"
              placeholder="Start Date"
              type="date"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </fg-input>
        </div>
      </div>
      <div class="col-sm-2">
        <div style="padding-top: 11px; padding-bottom: 11px; display: flex">
          <div style="line-height: 2.5"><span>To&nbsp;</span></div>
          <fg-input class="ml-3">
            <el-date-picker
              v-model="transactionFilter.period_end"
              placeholder="End Date"
              type="date"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </fg-input>
        </div>
      </div>
      <div class="col-md-2">
        <fg-input
          placeholder="Amount (min)"
          style="padding-top: 11px; padding-bottom: 11px; display: flex"
          v-model="transactionFilter.amount_min"
        ></fg-input>
      </div>
      <div class="col-md-2">
        <fg-input
          placeholder="Amount (max)"
          style="padding-top: 11px; padding-bottom: 11px; display: flex"
          v-model="transactionFilter.amount_max"
        ></fg-input>
      </div>
      <div class="col-md-2">
        <fg-input
          placeholder="Confirmation No"
          addon-right-icon="fa fa-search"
          style="padding-top: 11px; padding-bottom: 11px; display: flex"
          v-model="transactionFilter.search"
        ></fg-input>
      </div>
    </div>
    <div class="row">
      <div class="col-md-2">
        <div>
          <el-select
            v-model="transactionFilter.point_type"
            class="select-info"
            filterable
            placeholder="Point Type"
          >
            <el-option
              class="select-info"
              v-for="(option, i) in pointType"
              :key="i"
              :label="option.name"
              :value="option.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="col-md-2">
        <div>
          <el-select
            v-model="transactionFilter.type"
            class="select-info"
            filterable
            placeholder="Type"
          >
            <el-option
              class="select-info"
              v-for="(option, i) in trxType"
              :key="i"
              :label="option.name"
              :value="option.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="col-md-2">
        <div>
          <el-select
            v-model="transactionFilter.voucher"
            class="select-info"
            filterable
            placeholder="Voucher"
          >
            <el-option
              class="select-info"
              v-for="(option, i) in voucherList"
              :key="i"
              :label="option.langs[0].name"
              :value="option.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="col-md-2">
        <div>
          <el-select
            v-model="transactionFilter.voucher_status"
            class="select-info"
            filterable
            placeholder="Voucher Status"
          >
            <el-option
              class="select-info"
              v-for="(option, i) in voucherStatus"
              :key="i"
              :label="option.name"
              :value="option.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="col-md-2">
        <div>
          <el-select
            v-model="transactionFilter.property_code"
            class="select-info"
            filterable
            placeholder="Property"
          >
            <el-option
              class="select-info"
              v-for="(option, i) in propertyList"
              :key="i"
              :label="option.langs[0].name"
              :value="option.code"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm">
        <n-button
          @click.native="btnClearFilterClick"
          class="ml-2 btn-border"
          type="info"
        >
          CLEAR FILTER
        </n-button>
        <n-button
          style="margin-left: 10px"
          class="btn btn-info"
          @click.native="changePage(1)"
        >
          APPLY FILTER
        </n-button>
        <n-button
          @click.native="btnExportToExcelClick"
          class="ml-2"
          type="success"
        >
          EXPORT TO EXCEL
        </n-button>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="col-md-4"></div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <el-table v-loading="loading" :data="transaction.list">
          <el-table-column min-width="200" prop="Date" label="Date">
            <template scope="scope">
              <span style="color: black">{{
                scope.row.trx_time | formatDate
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            min-width="250"
            prop="Conf No"
            header-align="center"
            align="center"
            label="Confirmation No"
          >
            <template scope="scope">
              <span style="color: black">{{ scope.row.confirmation_no }}</span>
            </template>
          </el-table-column>
          <el-table-column
            min-width="250"
            prop="Point Type"
            header-align="center"
            align="center"
            label="Point Type"
          >
            <template scope="scope">
              <span style="color: black">{{
                scope.row.json_info | parsePointType
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            min-width="250"
            prop="Voucher"
            header-align="center"
            label="Voucher"
          >
            <template scope="scope">
              <span v-if="scope.row.order_items.length > 0">
                <span
                  v-for="item in scope.row.order_items"
                  :key="item.id"
                  style="color: black"
                  >{{ item.item_name }}<br />
                </span>
              </span>
              <span v-else>
                {{ scope.row.item_name }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            min-width="250"
            prop="Voucher Status"
            header-align="center"
            align="center"
            label="Voucher Status"
          >
            <template scope="scope">
              <span>
                {{ scope.row.order_status | capitalized }}
              </span>
            </template>
          </el-table-column>
          <el-table-column min-width="250" prop="Property" label="Property">
            <template scope="scope">
              <span style="color: black">
                {{ parseProperty(scope.row.property) }}</span
              >
            </template>
          </el-table-column>
          <el-table-column min-width="150" prop="Type" label="Type">
            <template scope="scope">
              <span style="color: black; text-transform: capitalize">
                {{ scope.row.type | formatType }}</span
              >
            </template>
          </el-table-column>
          <el-table-column min-width="80" prop="Qty" align="right" label="Qty">
            <template scope="scope">
              <span style="color: black">
                {{ scope.row.order_items | formatQuantity }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            min-width="150"
            prop="Amount"
            align="right"
            label="Amount"
          >
            <template scope="scope">
              <span
                style="color: black"
                :class="{ minus: isMinus(scope.row.type) }"
              >
                {{ formatAmount(scope.row, scope.row.trx_amount) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            min-width="150"
            prop="Basic Point"
            align="right"
            label="Basic Point"
          >
            <template scope="scope">
              <span
                style="color: black"
                :class="{ minus: isMinus(scope.row.type) }"
              >
                {{ formatAmount(scope.row, scope.row.point) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            min-width="150"
            prop="Bonus Point"
            align="right"
            label="Bonus Point"
          >
            <template scope="scope">
              <span
                style="color: black"
                :class="{ minus: isMinus(scope.row.type) }"
              >
                {{
                  formatAmount(
                    scope.row,
                    scope.row.bonus_point + scope.row.level_point
                  )
                }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            min-width="150"
            prop="Night"
            align="right"
            label="Night"
          >
            <template scope="scope">
              <span
                style="color: black"
                :class="{ minus: isMinus(scope.row.type) }"
              >
                {{ formatAmount(scope.row, scope.row.night) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column min-width="250" prop="Trx No" label="Trx Number">
            <template scope="scope">
              <span style="color: black">{{ scope.row.trx_no }}</span>
            </template>
          </el-table-column>
          <el-table-column
            min-width="200"
            header-align="center"
            prop="Action"
            label="Action"
          >
            <template slot-scope="scope">
              <div class="text-center table-actions">
                <el-tooltip
                  content="Go to Redeem Page"
                  :open-delay="300"
                  placement="top"
                >
                  <n-button
                    @click.native="redeemPage(scope.row)"
                    type="info"
                    size="sm"
                    :hidden="scope.row.order_id == null"
                    :disabled="!hasRole('read')"
                    icon
                  >
                    <i class="now-ui-icons tech_tv"></i>
                  </n-button>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="pull-right py-3">
      <n-pagination
        type="info"
        :pageCount="transaction.pagination.totalPages"
        :perPage="transaction.pagination.perPage"
        v-model="currentPage"
        @input="changePage"
      ></n-pagination>
    </div>
  </card>
</template>
<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import {
  Table,
  TableColumn,
  MessageBox,
  Loading,
  DatePicker,
  Select,
  Option,
} from "element-ui";
import { Pagination } from "src/components";
import moment from "moment";
import Helper from "@/util/helper";
import { findUserRole } from "@/util/userRoles";
import VouchersAPI from "@/api/vouchers";
import PointAdjustment from "@/api/pointAdjustment";

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Pagination.name]: Pagination,
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  created: function () {
    this.cardNumber = this.$route.params.cardNumber;
    this.btnClearFilterClick();
    this.getVoucherList();
    this.getPropertyList();
  },
  computed: {
    ...mapState("members", ["transaction", "transactionFilter"]),
  },
  data() {
    return {
      loading: true,
      currentPage: 0,
      cardNumber: "",
      voucherList: {},
      propertyList: {},
      trxType: [
        { name: "Select Type", value: "" },
        { name: "Top Up", value: "top_up" },
        { name: "Top Up Manual", value: "top_up_manual" },
        { name: "Deduct", value: "deduct" },
        { name: "Deduct Manual", value: "deduct_manual" },
        { name: "Redeem Point", value: "redeem_point" },
        { name: "Adjustment", value: "adjustment" },
        { name: "Activation", value: "activation" },
        { name: "Expired", value: "expired" },
        { name: "Void", value: "void" },
      ],
      pointType: [
        { name: "Select Point Type", value: "" },
        { name: "Spa Manual", value: "SPA MANUAL" },
        { name: "Room Manual", value: "ROOM MANUAL" },
        { name: "FB Manual", value: "FB MANUAL" },
        { name: "Promotion", value: "PROMOTION" },
        { name: "Other Manual", value: "OTHER MANUAL" },
        { name: "Wedding", value: "WEDDING" },
        { name: "Activity", value: "ACTIVITY" },
      ],
      voucherStatus: [
        { name: "Select Voucher Status", value: "" },
        { name: "On Process", value: "on process" },
        { name: "Completed", value: "completed" },
        { name: "Void", value: "void" },
      ],
    };
  },
  filters: {
    parsePointType(value) {
      if (value === undefined || value === "") return "";

      const json = JSON.parse(value);

      if (json != null && json.hasOwnProperty("point_type")) {
        return json.point_type;
      } else {
        return "";
      }
    },
    formatType(value) {
      return value != null ? value.replaceAll("_", " ") : value;
    },
    formatDate(value) {
      return moment(value).format("D MMM YYYY HH:mm:ss");
    },
    formatQuantity(orderItems) {
      let quantity = 0;

      for (let i = 0; i < orderItems.length; i++) {
        quantity += parseInt(orderItems[i].quantity);
      }

      return quantity;
    },
  },
  methods: {
    ...mapActions("members", [
      "getTransactionHistories",
      "exportTransactionHistoriesToExcel",
    ]),
    ...mapMutations("members", ["CLEAR_TRANSACTION_FILTER"]),
    hasRole(role) {
      return findUserRole(role, "redeem");
    },
    parseProperty(property) {
      if (property) {
        const langs = property.lang.filter((item) => item.lang === "EN");
        if (langs.length) {
          return langs[0].name;
        }
      }
      return "";
    },
    redeemPage(transaction) {
      this.$router.push({
        name: "E-Voucher Management Details",
        params: {
          id: transaction.id,
          slug: this.getSlugFromLangs(transaction.order_items[0].langs),
        },
      });
    },
    getSlugFromLangs(langs) {
      if (langs.length) {
        const lang = langs[0];
        return Helper.convertToSlug(lang.name);
      }
      return "no-name";
    },
    isMinus(type) {
      return type === "deduct" || type === "deduct_manual";
    },
    formatAmount(transaction, amount) {
      const number = new Intl.NumberFormat().format(amount);

      if (
        transaction.type === "deduct" ||
        transaction.type === "deduct_manual"
      ) {
        return "-" + number;
      }

      return number;
    },
    btnClearFilterClick: function () {
      this.CLEAR_TRANSACTION_FILTER();
      this.changePage(1);
    },
    changePage(page) {
      this.currentPage = page;
      this.loading = true;
      this.getTransactionHistories({ cardNumber: this.cardNumber, page })
        .then((response) => {
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    getVoucherList() {
      VouchersAPI.getVouchers({
        page: 1,
        limit: 100,
      }).then((response) => {
        this.voucherList = response.data.data;
      });
    },
    getPropertyList() {
      PointAdjustment.getPlaceOfVisit().then((response) => {
        this.propertyList = response.data.data;
      });
    },
    btnExportToExcelClick: function () {
      this.exportTransactionHistoriesToExcel({ cardNumber: this.cardNumber })
        .then((response) => {
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped>
.minus {
  color: red !important;
}
</style>
